var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "h1",
      {
        class: [
          `${!_vm.$screen.sm ? "main-page-title" : ""}`,
          { "is-pwa": _vm.$isPwa() },
        ],
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.FormMSG(2, "Locations list")) + "\n\t")]
    ),
    _c(
      "div",
      {
        staticClass: "container-mobile animated fadeIn",
        staticStyle: { top: "95px" },
      },
      [
        _c(
          "div",
          { staticClass: "form content-contact-mobile" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c("b-form-group", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ ariaDescribedby }) {
                            return [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "showOptionLocation",
                                  options: _vm.showOptions,
                                  "aria-describedby": ariaDescribedby,
                                  name: "radio-options",
                                  plain: "",
                                },
                                on: {
                                  change: _vm.handleChangeShowOptionSelected,
                                },
                                model: {
                                  value: _vm.showOptionSelected,
                                  callback: function ($$v) {
                                    _vm.showOptionSelected = $$v
                                  },
                                  expression: "showOptionSelected",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c("b-col", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticClass: "form-input-search" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "search",
                          placeholder: _vm.FormMSG(9, "Type to Search"),
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-input-icon-search",
                      staticStyle: { cursor: "pointer" },
                    },
                    [
                      _vm.filter === ""
                        ? _c(_vm.getLucideIcon("Search"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 16 },
                          })
                        : _c(_vm.getLucideIcon("X"), {
                            tag: "component",
                            attrs: { color: "#06263E", size: 16 },
                            on: {
                              click: function ($event) {
                                _vm.filter = ""
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-3 mb-3" },
              [
                _c("b-col", [
                  _c("div", { staticClass: "title-list" }, [
                    _c("div", { staticClass: "fs-16 fw-400" }, [
                      _vm._v(_vm._s(_vm.FormMSG(2, "Locations list"))),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _vm.locationsFilteredMap.length === 0
              ? _c("div", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(587, "No data found")) +
                      "\n\t\t\t"
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              [
                _vm._l(_vm.locationsFilteredMap, function (location, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      index > 0
                        ? _c("div", { staticClass: "divider-line mt-3 mb-3" })
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "10" } }, [
                            _c("p", { staticClass: "fs-16 fw-700 mb-1" }, [
                              _vm._v(_vm._s(location.setName)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "wrap-status d-flex justify-content-start",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "status info fs-11 fw-700" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "txt-capitalize" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                location.getInDate,
                                                "DD MMMM YYYY"
                                              )
                                            ) +
                                            " -\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                location.getOutDate,
                                                "DD MMMM YYYY"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "2" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-center h-100-percent",
                              },
                              [
                                _c(
                                  "b-dropdown",
                                  {
                                    attrs: {
                                      "no-caret": "",
                                      variant: "none",
                                      dropleft: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "MoreVertical"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 20 },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickEdit(location)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-transparent text-color-rhapsody-in-blue",
                                          },
                                          [
                                            _c(_vm.getLucideIcon("Edit"), {
                                              tag: "component",
                                              attrs: { size: 16 },
                                            }),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(47, "Edit")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    location.images.length > 0
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickShowImage(
                                                  location
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                              },
                                              [
                                                _c(_vm.getLucideIcon("File"), {
                                                  tag: "component",
                                                  attrs: { size: 16 },
                                                }),
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        45,
                                                        "Show image"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDelete(
                                              location
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-transparent text-color-burning-tomato",
                                          },
                                          [
                                            _c(_vm.getLucideIcon("Trash2"), {
                                              tag: "component",
                                              attrs: { size: 16 },
                                            }),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(46, "Delete")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "b-row",
                  { staticClass: "footer-fixed" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "700",
                            },
                            attrs: { variant: "primary", block: "" },
                            on: { click: _vm.handleClickAddLocation },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(85, "Add location")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }