var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "info" }, [
          _c("h6", [_vm._v(_vm._s(_vm.item.setName))]),
        ]),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "none" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.handleCardClicked()
                  },
                },
              },
              [
                _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                  tag: "component",
                  attrs: { color: _vm.ICONS.EDIT.color, size: 16 },
                }),
              ],
              1
            ),
            _vm.item.images.length > 0
              ? _c(
                  "b-button",
                  {
                    staticClass: "ml-4",
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.showPicture(_vm.item.images)
                      },
                    },
                  },
                  [
                    _c(_vm.getLucideIcon(_vm.ICONS.PICTURE.name), {
                      tag: "component",
                      attrs: { color: _vm.ICONS.PICTURE.color, size: 16 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showDeleteButton
              ? _c(
                  "b-button",
                  {
                    staticClass: "ml-4",
                    attrs: { variant: "none" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.handleDeleteLocation(_vm.item.id)
                      },
                    },
                  },
                  [
                    _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                      tag: "component",
                      attrs: { color: _vm.ICONS.TRASH.color, size: 16 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "foot" }, [
        _c("span", {
          staticClass: "date",
          domProps: { innerHTML: _vm._s(_vm.rendStartEndDate) },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }