<template>
	<div class="card">
		<div class="card-body">
			<div class="content">
				<div class="info">
					<h6>{{ item.setName }}</h6>
				</div>
				<div class="actions">
					<b-button variant="none" @click.prevent.stop="handleCardClicked()">
						<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="16" />
					</b-button>
					<b-button variant="success" v-if="item.images.length > 0" class="ml-4" @click.prevent.stop="showPicture(item.images)">
						<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="16" />
					</b-button>
					<b-button v-if="showDeleteButton" variant="none" class="ml-4" @click.prevent.stop="handleDeleteLocation(item.id)">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="16" />
					</b-button>
				</div>
			</div>
			<div class="foot">
				<span class="date" v-html="rendStartEndDate" />
			</div>
		</div>
	</div>
</template>

<script>
import { formatTableDate } from '~helpers';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'MobileViewsLocationItemComponent',
	mixins: [GlobalMixin],
	props: {
		item: {
			type: Object,
			required: true,
			default: () => {}
		},
		showDeleteButton: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed: {
		rendStartEndDate() {
			const { getInDate, getOutDate } = this.item;
			const _f = formatTableDate;
			return `${_f(getInDate)} - ${_f(getOutDate)}`;
		}
	},
	methods: {
		/**
		 * @param {Array}
		 * @return {Emitter}
		 */
		showPicture(images) {
			this.$emit('show-picture', images);
		},
		handleDeleteLocation(id) {
			this.$emit('delete-location', id);
		},

		handleCardClicked() {
			this.$emit('card-clicked', this.item);
		}
	}
};
</script>

<style lang="scss" scoped>
.content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.foot {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;
}
</style>
